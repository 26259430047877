import React from 'react'

import { Helmet } from 'react-helmet'

import SolidButton from '../components/solid-button'
import PlaceCard from '../components/place-card'
import './landing-page.css'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'


const LandingPage = (props) => {
  return (
    <div className="landing-page-container">
      <Helmet>
        <title>Online Stotra and Shloka classes</title>
        <meta property="og:title" content="Online Stotra and Shloka classes" />
          <meta name="description" content=" Learn Shloka Stotra Sanskrit and Veda Mantra from a learned teacher. Classes will be one to one basis and based on your needs"/>
          <meta name="keywords" content="Shloka Stotra Mantra Veda Sanskrit"/>
          <link rel="canonical" href="https://stotraclass.com/" />
      </Helmet>
      <div className="landing-page-top-container">
        <nav data-role="Header" className="landing-page-navbar">
          <h1>Online Veda Patha Shala </h1>          
        </nav>
        <div className="landing-page-hero">
          <div className="landing-page-content-container">
          <h3>Stotra, Shloka, Sanskrit and Veda Mantra : One to One customized online classes</h3>
              <span>
                Teacher Mr Ramakrishna Bhat is a Veda practitioner,
                Sanskrit and Veda classes also available.
                Contact Us On WhatsApp
                      </span>

              <a href="https://api.whatsapp.com/send?phone=9449112464" class="place-card-text">9449112464 </a>              
                  <span/>
                  <img alt="image" src="RKB.jpg" class="place-card-image"></img>
          </div>
        </div>
      </div>
      <div id="main-section" className="landing-page-main">
        
        <Stack direction="column" spacing={2}>
        <Button variant="contained" href= "/stotra">Learn Stotras</Button>
        <Button variant="contained" href= "/mantra">Learn Veda Mantra</Button>
        </Stack>
        
      </div>
    </div>
  )
}

export default LandingPage
