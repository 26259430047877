import React from 'react'

import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import './outline-button.css'

const OutlineButton = (props) => {
  return (
 
      <Button variant="contained" href={props.link}>{props.button1}</Button>


  )
}

OutlineButton.defaultProps = {
  button1: 'Button',
}

OutlineButton.propTypes = {
  button1: PropTypes.string,
  link: PropTypes.href
}

export default OutlineButton
