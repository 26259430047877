import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


import './style.css'
import LandingPage from './views/landing-page'
import MantraPage from './views/mantra-landing-page'
import StotraPage from './views/stotra-landing-page'

const App = () => {
  return (
    <Router>
      <div>
        <Route component={LandingPage} exact path="/" />
        <Route component={MantraPage} exact path="/mantra" />
        <Route component={StotraPage} exact path="/stotra" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
