import React from 'react'

import PropTypes from 'prop-types'
import Button from '@mui/material/Button'

import './solid-button.css'

const SolidButton = (props) => {
  return (
    <Button variant="contained">{props.button}</Button>
  
  )
}

SolidButton.defaultProps = {
  button: 'Button',
}

SolidButton.propTypes = {
  button: PropTypes.string,
}

export default SolidButton
